import { Component } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  weeksString = '';
  daysString = '';
  hoursString = '';
  minutesString = '';
  secondsString = '';
  frameClass = 'frame0';

  constructor() {
    setInterval(() => this.update(), 1000);
  }

  private update() {
    const now = moment();
    const depart = moment('2023-12-18T12:00:00', 'YYYY-MM-DD HH:mm:ss');
    var diff = depart.diff(now);
    var duration = moment.duration(diff);

    const weeks = Math.floor(duration.asWeeks());
    this.weeksString = this.toText(weeks, 'week');
    duration = duration.subtract(weeks, 'weeks');

    const days = Math.floor(duration.asDays());
    this.daysString = this.toText(days, 'day');
    duration = duration.subtract(days, 'days');

    const hours = Math.floor(duration.asHours());
    this.hoursString = this.toText(hours, 'hour');
    duration = duration.subtract(hours, 'hours');

    const minutes = Math.floor(duration.asMinutes());
    this.minutesString = this.toText(minutes, 'minute');
    duration = duration.subtract(minutes, 'minutes');

    const seconds = Math.floor(duration.asSeconds());
    this.secondsString = this.toText(seconds, 'second');

    const frame = Math.floor(now.seconds() / 8) % 7;
    this.frameClass = `frame${frame}`;
  }

  private toText(val: number, text: string) {
    if (val == 1) return `${val} ${text}`;
    else return `${val} ${text}s`;
  }
}
